import './CustomNavbar.css';
import React, { useState, useEffect } from "react";
import { Navbar, Nav } from 'react-bootstrap';

const CustomNavbar = () => {
  const [navbarBackground, setNavbarBackground] = useState('linear-gradient(to right, #1B1B1B, #1B1B1B)');
  const [logoSrc, setLogoSrc] = useState('/assests/img/logowhite.png');
  const [textColor, setTextColor] = useState('#B5B5B5');
  const [hasScrolled, setHasScrolled] = useState(false);

  const toggleStyle = {
    backgroundImage: navbarBackground,
    transition: 'background-color 0.3s ease',
    color: `${textColor} !important`,
    boxShadow: hasScrolled ? '1px 2px 10px rgba(0, 0, 0, 0.1)' : 'none'
  };

  const toggleButtonStyle = {
    backgroundColor: '#b0b0b0',
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navbarHeight = document.querySelector('.navbar').offsetHeight;

      if (scrollPosition > navbarHeight) {
        setNavbarBackground('linear-gradient(to right, #ffffff, #ffffff)');
        setLogoSrc('/assests/img/logoblack.png');
        setTextColor('black');
        setHasScrolled(true);
      } else {
        setNavbarBackground('linear-gradient(to right, #1B1B1B, #1B1B1B)', 'boxShadow:none');
        setLogoSrc('/assests/img/logowhite.png');
        setTextColor('#B5B5B5');
        setHasScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar className="p-3 fixed-top navheigh" style={toggleStyle} variant="light" expand="lg">
      <Navbar.Brand href="/">
        <img className="img-fluid px-3" src={logoSrc} alt="Logo" style={{ width: "200px", height: "40px" }} />
      </Navbar.Brand>
      <Navbar.Toggle className="border-0" aria-controls="basic-navbar-nav" style={toggleButtonStyle} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto px-5">
          <Nav.Link className="nav-link-custom" style={{ color: textColor }} href="/">HOME</Nav.Link>
          <Nav.Link className="nav-link-custom" style={{ color: textColor }} href="/products">PRODUCTS</Nav.Link>
          <Nav.Link className="nav-link-custom" style={{ color: textColor }} href="/careers">CAREERS</Nav.Link>
          <Nav.Link className="nav-link-custom" style={{ color: textColor }} href="/blogs">BLOGS</Nav.Link>
          <Nav.Link className="nav-link-custom" style={{ color: textColor }} href="/contacts">CONTACT US</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
