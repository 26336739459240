import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import blogsData from './blogsData';
import './BlogInsiderStyle.css'
import he from 'he';
import BlogFeed from './BLogFeed';



const BlogDetail = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        //console.log('Fetching blog with ID:', id);
        const foundBlog = blogsData.find(blog => blog.id === parseInt(id));
        //console.log('Found blog:', foundBlog);
        setBlog(foundBlog);
    }, [id]);

    //console.log('Blog state:', blog);

    if (!blog) {
        return <div>Loading...</div>;
    }

    const otherBlogs = blogsData.filter(b => b.id !== parseInt(id));

    return (
        <>
            <div className='row' style={{ marginTop: "5rem" }}>
                <div className='col-lg-2'></div>
                <div className='col-lg-8'>
                    <div className="container bloginsider-container my-5">
                        <div className="row bloginsider-row" >
                            <h2>{blog.title}</h2>
                            <b>{blog.date}</b><br />
                            <img className=" img-fluid mt-4" src={blog.image} alt="logo" />
                        </div>
                        {/* <div className="row bloginsider-row-content mt-5 mb-5" style={{ fontSize: "1.2rem", color: "gray" }}  > <p>{blog.content}</p></div> */}
                        <div className="row bloginsider-row-content mt-5 mb-5" style={{ fontSize: "1.2rem", color: "gray" }}
                            dangerouslySetInnerHTML={{ __html: he.decode(blog.content) }} />
                    </div>
                </div>
                <div className='col-lg-2'></div>
            </div>

            <div className='container mb-5'>
            <h2 className='text-center mb-5 mt-5'>Explore More</h2>
            <BlogFeed blogs={otherBlogs.slice(0,3).reverse()}/>
            </div>
        </>



    );
};

export default BlogDetail;
