import React from 'react';
import Home from './Components/Home/Home';
import Products from './Components/Product/Products';
import Careers from './Components/Careers/Careers';
import Blogs from './Components/Blog/Blogs';
import Contacts from './Components/Contacts/Contacts';
import { Route, Routes } from 'react-router-dom';
// import BlogInsider from './Components/Blog/BlogInsider';
// import BlogInsider2 from './Components/Blog/BlogInsider2';
// import BlogInsider3 from './Components/Blog/BlogInsider3';
// import BlogInsider4 from './Components/Blog/BlogInsider4';
// import BlogInsider5 from './Components/Blog/BlogInsider5';
// import BlogInsider6 from './Components/Blog/BlogInsider6';
import BlogFeed from './Components/Blog/BLogFeed';
import blogsData from './Components/Blog/blogsData';
import BlogDetail from './Components/Blog/BlogDetail';


function App_Router() {
    return (
        <div>
            <Routes>
                < Route path="/" element={<Home />}></Route>
                <Route path="/products" element={<Products />}></Route>
                <Route path="/careers" element={<Careers />}></Route>
                <Route path="/blogs" element={<Blogs />}></Route>
                <Route path="/contacts" element={<Contacts />}></Route>

                <Route path="/" element={<BlogFeed blogs={blogsData} />} />
                <Route path="/blog/:url/:id" element={<BlogDetail />} />






            </Routes>
        </div>
    );
}

export default App_Router;