// BlogFeed.js
import React from 'react';
import BlogItem from './BlogItem';
import './BlogInsiderStyle.css';

const BlogFeed = ({ blogs, onItemClick }) => {
  return (
    <div>
      {/* <h2 className='text-center mb-5 mt-5'>Read our latest blogs</h2> */}
      <div className="row">
        {blogs.map((blog, index) => (
          <BlogItem key={index} blog={blog} onItemClick={() => onItemClick(blog)} />
        ))}
      </div>
    </div>
  );
};

export default BlogFeed;
