// BlogItem.js
import React from 'react';
import './BlogInsiderStyle.css';

const BlogItem = ({ blog }) => {

  return (
    <div className='col-lg-4 items-row'>
      <a href={`/blog/${blog.url}/${blog.id}`} rel="noopener noreferrer" className="blog-item mt-4" style={{textDecoration:"none", color:"#000"}}>
        <div className="client-feature-item">
          <img className="img-fluid client-img mb-4 mt-4" src={blog.image} alt={blog.title} />
          <div>
            <h3><b>{blog.title.substring(0, 50)}</b></h3>
            <p className="client-card-content">{blog.content.substring(0, 80)+"...."}</p>
            <p className="client-card-date"><b>{blog.date}</b></p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default BlogItem;
