import './CareersStyle.css'
import OpenPositions from "./OpenPosition";
import WhyUs from "./WhyUs";

function Careers() {
  return (
    <div className="container-fluid careers-container" >
      <div className="row careers-header-section" >
        <div className="col-lg-6 col-md-6 col-sm-6">
          <img className="img-fluid client-img" src="/assests/img/bepart.gif" alt="logo" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 careers-header-col" >
          <h1>
            Be a part of this<br />
            diligent team.
          </h1>
          <p >Our promises designs tomorrow together<br />
            is at heart of everything we do</p>
        </div>
      </div>
      <OpenPositions />
      <WhyUs />
    </div >
  );
}

export default Careers;
