import { useEffect, useState } from 'react';
import BlogFeed from './BLogFeed';
import './BlogStyle.css'
import blogsData from './blogsData';

function Blogs() {
  // State variables to hold featured blog data
  const [featuredBlog, setFeaturedBlog] = useState({});

  console.log("this is blog data =========>", blogsData)

  // Fetching the first blog from the static blogsData array and setting it as the featured blog
  useEffect(() => {
    if (blogsData && blogsData.length > 0) {
      setFeaturedBlog(blogsData[4]);
      console.log("this is fetaured", featuredBlog)
    }
  }, [featuredBlog]);



  return (
    <div className="container-fluid blog-section">
      <a href={`/blog/${featuredBlog.url}/${featuredBlog.id}`} style={{ textDecoration: "none" }}>
        <div className="row align-items-end blog-featured-section" style={{ backgroundImage: `url(${featuredBlog.image})` }} >
          <div className=" blog-fea-sec col-lg-12 col-md-12 col-sm-12 pt-5 ">
            <h1 className="pt-5">FEATURED</h1>
            <h3 className="pt-2">{featuredBlog.title}</h3>
            <p className="pt-2">
              {featuredBlog.content?.substring(0, 150) + "..."}
            </p>
          </div>
        </div>
      </a>



      {/* part-1 ends */}


      {/* part-2 starts */}
      {/* <div className="row blog-featured-content">
        <a href="/bloginsider" className=" col-lg-4 col-md-4 col-sm-12 blog-featured-content-item p-4" >
          <div className="blog-feature-item">
            <div>
              <img className="img-fluid blog-img" src="/assests/img/card1.png" alt="logo" />
            </div>
            <div className="pt-3">
              <h4>How I Use the iPad Pro To DO
                Actual Work?</h4>
              <p className="blog-para1" >Our promises, designs tomorrow
                together, is at the heart of everything...</p>
              <p className="blog-card-content" ><b>02 November 2023</b></p>
            </div>
          </div>
        </a>
        <a href="/bloginsider5" className="col-lg-4 col-md-4 col-sm-12  blog-featured-content-item p-4">
          <div className="blog-feature-item">
            <div>
              <img className="img-fluid blog-img" src="/assests/img/card3.png" alt="logo" />
            </div>
            <div className="pt-3">
              <h4 >The Non Profit Organistion working at its best.</h4>
              <p className="blog-para1">Our promises, designs tomorrow
                together, is at the heart of everything...</p>
              <p className="blog-card-content" ><b>02 November 2023</b></p>
            </div>
          </div>
        </a>
        <a href="/bloginsider3" className="col-lg-4 col-md-4 col-sm-12 blog-featured-content-item p-4"  >
          <div className="blog-feature-item">
            <div>
              <img className="img-fluid blog-img" src="/assests/img/card2.png" alt="logo" />
            </div>
            <div className="pt-3">
              <h4>How AI is bringing a brig
                change in IT industry.</h4>
              <p className="blog-para1">Our promises, designs tomorrow
                together, is at the heart of everything...</p>
              <p className="blog-card-content" ><b>02 November 2023</b></p>
            </div>
          </div>
        </a>
        <a href="/bloginsider4" className="col-lg-4 col-md-4 col-sm-12  blog-featured-content-item p-4" >
          <div className="blog-feature-item">
            <div>
              <img className="img-fluid blog-img" src="/assests/img/card1.png" alt="logo" />
            </div>
            <div className="pt-3">
              <h4>How I Use the iPad Pro To DO
                Actual Work?</h4>
              <p className="blog-para1">Our promises, designs tomorrow
                together, is at the heart of everything...</p>
              <p className="blog-card-content" ><b>02 November 2023</b></p>
            </div>
          </div>
        </a>
        <a href="/bloginsider5" className="col-lg-4 col-md-4 col-sm-12 blog-featured-content-item p-4" >
          <div className="blog-feature-item">
            <div>
              <img className="img-fluid blog-img" src="/assests/img/card3.png" alt="logo" />
            </div>
            <div className="pt-3">
              <h4>How I Use the iPad Pro To DO
                Actual Work?</h4>
              <p className="blog-para1">Our promises, designs tomorrow
                together, is at the heart of everything...</p>
              <p className="blog-card-content" ><b>02 November 2023</b></p>
            </div>
          </div>
        </a>
        <a href="/bloginsider6" className=" col-lg-4 col-md-4 col-sm-12 blog-featured-content-item p-4" >
          <div className="blog-feature-item">
            <div>
              <img className="img-fluid blog-img" src="/assests/img/card2.png" alt="logo" />
            </div>
            <div className="pt-3" >
              <h4>How AI is bringing a brig
                change in IT industry.</h4>
              <p className="blog-para1">Our promises, designs tomorrow
                together, is at the heart of everything...</p>
              <p className="blog-card-content" ><b>02 November 2023</b></p>
            </div>
          </div>
        </a>
      </div > */}



      <div className='container mb-5'>
        <h2 className='text-center mb-5 mt-5'>Explore More</h2>
        <BlogFeed blogs={blogsData} />
      </div>



      {/* part-2 ends */}

      {/* part-3 starts */}
      <div className="row client-contact-section p-5" >
        <div className="col-lg-8 col-md-6 col-sm-12 client-contact-section-fcol px-5" >
          <h2 className="client-contact-heading">Are you prepared optimize your
            company's growth potential?</h2>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12client-contact-section-scol" >
          <a href="/contacts"><button className="btn btn-lg rounded-pill client-contact-button " style={{
            background: "black", color: " white",
            fontWeight: "bold", padding: "1.3rem 4rem", textAlign: "center", fontSize: "1.5rem"
          }}>
            Contact now</button></a>
        </div>
      </div>
      {/* part-3 ends */}
    </div >
  );
}
export default Blogs;
