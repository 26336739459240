import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../App.css';
import { useEffect } from "react";
import $ from 'jquery';

function WhyUs() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        // jQuery logic to toggle visibility on hover
        $(document).ready(function () {
            $('.whyUs-card').hover(
                function () {
                    $(this).find('.whyUs-card-content').css('opacity', '0');
                    $(this).find('.whyUs-card img').css('opacity', '0');
                },
                function () {
                    $(this).find('.whyUs-card-content').css('opacity', '1');
                    $(this).find('.whyUs-card img').css('opacity', '1');
                }
            );
        });
    }, []);

    return (
        <div className="container whyus-container">
            <div className="row ourProcess p-5">
                <div className="col-lg-7 col-md-12 col-sm-12">
                    <h2>Our process starts<br /> with YOU.</h2>
                    <p className="pt-3">We discuss your ideas, discover your needs, concept, priorities, get to know your business/enterprise in and out.
                        Our team will work with you to formulate a strategy to maximize impact, increase your return on investment, and conceptualize
                        the solution.</p>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-end align-items-end">
                    < img className="img-fluid client-img" src="/assests/img/our process.svg" alt="logo" style={{ height: "350px", width: "100%" }} />
                </div>
            </div>

            {/*  part-1 end */}

            {/*  part-2 start */}
            <div className="row whyUs-row p-5">
                <h2 >Why work with us ?</h2>
                <div className="col-lg-4 col-md-12 col-sm-12 whyUs-col p-5" >
                    <div className="whyUs-card">
                        <div className="whyUs-card-content">
                            <p>A typical
                                day at
                                work.</p>
                            <p>
                                <img className="img-fluid" src="/assests/img/dot.png" alt="logo" />
                            </p>
                        </div>
                        <div className="whyUs-hover-content">
                            <p>At Foxaisr, work is more than just a job. We build, design, and code, working closely with clients to deliver the best products, and innovate.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 whyUs-col p-5 " >
                    <div className="whyUs-card" >
                        <div className="whyUs-card-content">
                            <p> Values
                                that we
                                live by.</p>
                            <p>
                                <img className="img-fluid" src="/assests/img/dot.png" alt="logo" />
                            </p>
                        </div>
                        <div className="whyUs-hover-content">
                            <p>At Foxaisr, work is more than just a job; it's about building, designing, coding, and innovating to meet our clients' needs and create markets.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 whyUs-col p-5">
                    <div className="whyUs-card">
                        <div className="whyUs-card-content" >
                            <p >Woking
                                with the
                                best.</p>
                            <p>
                                <img className="img-fluid" src="/assests/img/dot.png" alt="logo" />
                            </p>
                        </div>
                        <div className="whyUs-hover-content">
                            <p>At Foxaisr, work isn't just a job—it's a calling. We build, design, and code, meeting client demands, creating markets, and innovating.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/*  part-2 end */}

            {/*  part-3 starts */}
            <div className="row joining-fox py-5">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    < img className="img-fluid client-img" src="/assests/img/team checklist-rafiki 1.webp" alt="logo" />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 pt-5">
                    <p className="mt-5">
                        Joining Foxaisr transcends the conventional workplace experience; it embodies a journey through life itself. <br></br> <br></br>Each day presents us with fresh challenges to overcome, fostering growth and innovation within our team.
                    </p>
                </div>
            </div >
            {/*  part-3 ends */}



            {/* part-4 start */}
            {/* < div className="row ClientReview-row p-5 mb-5" >
                <div className="col-lg-5 col-md-5 col-sm-12 " >
                    <h1>What people say about our culture.</h1>
                    <p> Description of the first reason we give in a simple and understandable way in this many words</p>
                </div>
                <div className=" col-lg-1 col-md-1 col-sm-12" ></div>
                <div className=" card ClientReview-card1 col-lg-6 col-md-6 col-sm-12 ">
                    <div>
                        <Slider {...settings}>
                            {data.map((d, index) => (
                                <div key={index} className="testimonial-slide">
                                    <div>
                                        <p className="mt-2">{d.review}</p>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-10 col-md-6 col-sm-12">
                                            <h5 className="testimonial-name mt-3">{d.name}</h5>
                                            <p className="testimonial-location">{d.location}</p>
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                            <img src={d.img} alt={d.name} className="img-fluid rounded-circle testimonial-image" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div > */}

            {/* part-4 end */}
        </div >

    );
}
const data = [
    {
        name: `Prahlad Kumar`,
        location: `Banglore, INDIA`,
        img: `/assests/img/prahlad_kumar.png`,
        review: `Suraj has been easy to work with. He is willing to go the extra mile to satisfy requirements. 
        I have awarded multiple projects over the last year, evidence that he is reliable in delivery.`
    },
    {
        name: `Ramesh Kumar`,
        location: `Banglore, INDIA`,
        img: `/assests/img/prahlad_kumar.png`,
        review: `Suraj has been easy to work with. He is willing to go the extra mile to satisfy requirements. 
        I have awarded multiple projects over the last year, evidence that he is reliable in delivery.`
    },
    {
        name: `Prahlad Kumar`,
        location: `Banglore, INDIA`,
        img: `/assests/img/prahlad_kumar.png`,
        review: `Suraj has been easy to work with. He is willing to go the extra mile to satisfy requirements. 
        I have awarded multiple projects over the last year, evidence that he is reliable in delivery.`
    }

];

export default WhyUs;