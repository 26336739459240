import React from 'react';
import Footer from './Components/Footer/Footer';
import CustomNavbar from './Components/CustomNavbar/CustomNavbar';
import App_Router from './App_Router';
import './App.css';


function App() {
  return (
    <div>
      <CustomNavbar />
      <App_Router />
      <Footer />
    </div>
  );
}

export default App;
