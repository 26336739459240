import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import './ProductStyle.css'
function WorkProcess() {
    return (
        <>
            <div className="container mt-5">


                <div className='row' style={{ position: 'relative', marginTop: '-250px', height:"auto" }}>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <Card style={{ width: '100%', borderRadius:"3rem", boxShadow: "1px 5px 10px rgba(0.5, 0.5, 0.5, 0.3)" }} className='p-4 mb-3'>
                            <img alt="Sample" src="/assests/img/product_card.webp" style={{width:"100%"}}/>
                            <CardBody>
                                <CardTitle tag="h5"><h1>Simple Shiksha</h1></CardTitle>
                                <CardText style={{fontSize:"1.2rem"}}>Makes learning easy with user-friendly courses and interactive features for a better educational experience.</CardText>
                                <a href='https://www.simpleshiksha.com/' target='_blank'><button className="btn btn-lg rounded-pill visit-now-btn-top">Visit Now</button></a>
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <Card style={{ width: '100%', borderRadius:"3rem", boxShadow: "1px 5px 10px rgba(0.5, 0.5, 0.5, 0.3)" }} className='p-4'>
                            <img alt="Sample" src="/assests/img/product_card2.webp" style={{width:"100%"}} />
                            <CardBody>
                                <CardTitle tag="h5"><h1>Assist Buddi</h1></CardTitle>
                                <CardText style={{fontSize:"1.2rem"}}>Simplifies customer support with intuitive tools and automation, ensuring efficient service for businesses.</CardText>
                                <a href='https://www.assistbuddi.com/' target='_blank'><button className="btn btn-lg rounded-pill visit-now-btn-top">Visit Now</button></a>
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-lg-2 '></div>

                </div>

            </div>


            {/* --------------------------------------------------- */}
            {/* < div className='container actual-workprocess' >
                <div className='text-center pt-5'>
                    <h2 className='py-5'>Work Process</h2>
                    <img className="img-fluid" src="/assests/img/Frame 614.webp" alt="logo" />
                    <img className="img-fluid" src="/assests/img/Frame 615.webp" alt="logo" />
                    <img className="img-fluid" src="/assests/img/Frame 616.webp" alt="logo" />
                    <img className="img-fluid" src="/assests/img/Frame 617.webp" alt="logo" />
                </div>
                <div className='row pt-5'>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                        <h3>Concept and Design</h3>
                        <p>We start by grasping your ideas, needs, and business goals, collaborating closely to craft a strategy that maximizes impact. </p>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                        <h3>Development & ops</h3>
                        <p>We design optimal architecture, ensuring seamless tech integration with precision and expertise.
                        </p>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                        <h3>Testing & launch </h3>
                        <p>Testing finds bugs, team fixes, deploys, monitors, ensuring smooth functioning post-launch.
                        </p>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                        <h3>Update & Maintainence </h3>
                        <p>We ensure timely updates and maintenance to keep your website/application current and functional. </p>
                    </div>
                </div>
            </div > */}



        </>
    )
}

export default WorkProcess;




