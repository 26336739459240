import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ClientReview() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="container clientReview-container-new pt-5">
            <div className="row ClientReview-row pt-5 " >
                <div className="col-lg-6 col-md-12 col-sm-12" >
                    <h1>What our clients say about our work.</h1>
                    <p> Our clients consistently praise Foxasir for their exceptional attention to detail, timely delivery, and ability to exceed expectations.</p>
                </div>
                <div className="card ClientReview-card col-lg-6 col-md-12 col-sm-12 p-4">
                    <div>
                        <Slider {...settings}>
                            {data.map((d, index) => (
                                <div key={index} className="testimonial-slide">
                                    <div>
                                        <p className="mt-2">{d.review}</p>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-10 col-md-6 col-sm-12">
                                            <h5 className="testimonial-name mt-3">{d.name}</h5>
                                            <p className="testimonial-location">{d.location}</p>
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                            <img src={d.img} alt={d.name} className="img-fluid rounded-circle testimonial-image" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

const data = [
    {
        name: `Siddhart Jaganath`,
        location: `Texas, USA`,
        img: `/assests/img/siddhartjaganath.png`,
        review: `We’ve been blown away by FOXAISR excellence of work, attention to concept and ability to work closely with clients.`
    },
    {
        name: `Sajit K`,
        location: `Moline, IL, USA`,
        img: `/assests/img/avatar.png`,
        review: `Suraj has been easy to work with. He is willing to go the extra mile to satisfy requirements. I have awarded multiple projects over the last year, evidence that he is reliable in delivery.`
    },
    {
        name: `Prahlad Kumar`,
        location: `Banglore, INDIA`,
        img: `/assests/img/prahlad_kumar.png`,
        review: `First of all the FOXAISR has a great team that is not only technically well known but also humble. The team gives full effort to understand client requirements and work on the given feedback. All are enthusiastic about their work.`
    }
];

export default ClientReview;