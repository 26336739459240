import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';

function Why() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    // const totalCount = 3; // Total number of carousel items

    return (
        <>
            <div className="container home-why-container " >
                {/* part-1 start */}
                <div className="row home-why-frow ">
                    <div className="home-why-fcol col-lg-6 col-md-6 col-sm-12  text-center">
                        <img className="home-why-fimg img-fluid d-none d-md-block m-4 " src="/assests/img/2choos us.png" alt="logo" style={{ height: "54px", width: "91px" }} />
                    </div>
                    <div className="home-why-tcol  col-lg-6 col-md-6 col-sm-12 ">
                        <img className="img-fluid home-why-simg d-none d-md-block ms-auto text-md-end" src="/assests/img/3choos us.png" alt="logo" />
                    </div>
                </div>
                {/* part-1 end */}

                {/* part-2 start */}
                <div style={{ position: 'relative' }} >
                    <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} >
                        <Carousel.Item>
                            <div className="row home-why-srow">
                                <div className="home-why-col  col-lg-1 col-md-1 col-sm-12  "></div>
                                <div className="home-why-col  col-lg-10 col-md-10 col-sm-12  ">
                                    <h1 className="home-why-title">Why to choose us ?</h1>
                                    <h5>Best Performance</h5>
                                    <p>Foxaisr is dedicated to delivering top-quality products within deadlines. Our clients consistently recognize us as the best performer among all partners.</p>
                                </div>
                                <div className="home-why-col  col-lg-1 col-md-1 col-sm-12 "></div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="row home-why-srow">
                                <div className=" col-lg-1 col-md-1 col-sm-12  home-why-col"></div>
                                <div className="col-lg-10 col-md-10 col-sm-12  home-why-col">
                                    <h1 className="home-why-title">Why to choose us ?</h1>
                                    <h5>Dedicated Team Member</h5>
                                    <p>Because of having a smart and flexible team, we are  able to complete with the deadlines able to complete with to complete with the deadlines with the deadlines with the deadlines.</p>
                                </div>
                                <div className=" col-lg-1 col-md-1 col-sm-12  home-why-col"></div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="row home-why-srow">
                                <div className=" col-lg-1 col-md-1 col-sm-12  home-why-col"></div>
                                <div className=" col-lg-10 col-md-10 col-sm-12  home-why-col">
                                    <h1 className="home-why-title">Why to choose us ?</h1>
                                    <h5>24/7 Hours Support</h5>
                                    <p> Our team stands ready to swiftly identify and resolve any issues once your product goes live. Report a problem, and we'll tackle it immediately.</p>
                                </div>
                                <div className=" col-lg-1 col-md-1 col-sm-12 home-why-col"></div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                    {/* <div style={{ position: 'absolute', top: 0, right: 0, padding: '10px' }}>
                        {index + 1}/{totalCount}
                    </div> */}
                </div>
            </div>
            {/* part-2 ends */}


            {/* part-3 starts */}
            <div className='container home-why-new'>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-12">
                        <div className="d-flex justify-content-center">
                            <div className="home-why-stats d-inline-flex flex-column flex-sm-row">
                                <div className="text-center">
                                    <h1>240+</h1>
                                    <p>Successfully executed<br /> projects</p>
                                </div>
                                <div className="text-center px-5">
                                    <h1>50+</h1>
                                    <p>Served clients</p>
                                </div>
                                <div className="text-center">
                                    <h1>2 Flagship</h1>
                                    <p>Product Catering to the B2B<br /> Solution and Edtech sector</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* part-3 ends */}

            {/* part-4 starts */}
            <div className='container leading-content'>
                <div className="text-center">
                    <h1>Leading the Way in Software Development</h1>
                    <p>At FOXAISR, we excel in software design and development, ranking among
                        <br />the best in the industry.</p>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-3 col-md-3" data-aos="fade-up" data-aos-delay="300">
                            <div className="box-feature mb-4 text-center">
                                <img className="img-fluid mx-auto" src="/assests/img/experience.svg" alt="logo" style={{ width: "auto", height: "5rem" }} />
                                <p>Have more than 7<br /> years of experience</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3" data-aos="fade-up" data-aos-delay="500">
                            <div className="box-feature mb-4 text-center">
                                <img className="img-fluid mx-auto" src="/assests/img/solution.svg" alt="logo" style={{ width: "auto", height: "5rem" }} />
                                <p>Finding the right<br />   solutions for you.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3" data-aos="fade-up" data-aos-delay="400">
                            <div className="box-feature mb-4 text-center">
                                <img className="img-fluid mx-auto" src="/assests/img/skilled.svg" alt="logo" style={{ width: "auto", height: "5rem" }} />
                                <p>Skilled Professionals <br />  at Affordable Prices</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* part-4 ends */}

            {/* part-5 starts */}
            <div className='container why-technologies p-3 p-lg-5'>
                <div className=''>
                    <h1 className='text-center'>The <span className='gradient-text'>technologies</span> we work with</h1>
                </div>
                <div className='row'>
                    {/* Hide this div on medium and small devices */}
                    <div className='col-lg-6 col-md-12 col-sm-12 tech-react-col1 mt-5 d-none d-md-block pt-5'>
                        <p>
                            Any successful and quality software needs good performance and service, with the use of latest technology stacks and their combinations we create quality software to meet goals.
                        </p>
                        <p>
                            Our commitment to quality extends beyond technology stacks; we prioritize thorough testing, agile methodologies, and continuous improvement throughout the development lifecycle.
                        </p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12  p-3 p-md-5'>
                        <div className="d-flex justify-content-center justify-content-md-start">
                            <img className="img-fluid" src="/assests/img/langstack2.gif" style={{borderRadius:"3rem", width:"100%"}} alt="React JS logo" />
                        </div>
                    </div>

                </div>
            </div>



            {/* part-5 ends */}



        </>
    );
}
export default Why;
