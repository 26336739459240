import './ContactStyle.css'
function Contacts() {
  return (
    <div className="container-fluid contacts">
      {/* part 1 start */}
      <div className="row contacts-main">
        <div className="contacts-main-div col-lg-6 col-md-6 col-sm-12" >
          <h1>Contact us</h1>
          <p>
            We're ready to lead you into the future of
            digital world.We're ready to lead you into the
            future of digital world.
          </p>
        </div>
        <div className=" col-lg-6 col-md-6 col-sm-12  text-center" >
          <img src="/assests/img/Contact us-bro 1.svg" className="img-fluid" alt="logo" />
        </div>
      </div>
      {/* part 1 end */}

      {/* part 2 start */}
      <div className='container'>
        <div className="row contacts-details p-5 mt-5 mb-5">
          {/* Office Detail Start here */}
          <div className="con-info  6 col-xl-6 col-md-6 col-sm-12 col-xs-12 ">
            <div className="row" style={{marginTop:"6rem"}}>
              <div className="contacts-icon  col-lg-2 col-md-2 col-sm-12 ">
                <img className="img-fluid" src="/assests/img/location.svg" alt="logo" />
              </div>
              <div className="contacts-info col-lg-10 col-md-10 col-sm-12 ">
                <p>238, Scheme No. 114 Part 1, Opposite Mahindra Showroom, Vijay Nagar, AB Road, Indore M.P. (452010)-India</p>
              </div>
            </div>
            <div className="row">
              <div className="contacts-icon  col-lg-2 col-md-2 col-sm-12 ">
                <img className="img-fluid" src="/assests/img/phone.svg" alt="logo" />
              </div>
              <div className="contacts-info col-lg-10 col-md-10 col-sm-12">
                <p>+91 808 437 8326 <br /> +91 829 205 0483</p>
              </div>
            </div>
            <div className="row">
              <div className="contacts-icon col-lg-2 col-md-2 col-sm-12 ">
                <img className="img-fluid" src="/assests/img/mail.svg" alt="logo" />
              </div>
              <div className="contacts-info col-lg-10 col-md-10 col-sm-12 ">
                <p>info@foxaisr.com</p>
              </div>
            </div>
            {/* Embedded Map */}
            {/* <div className="row map-row py-4">
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.9160871674612!2d75.89623277530626!3d22.7684951793527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e3a5d3408dab%3A0x7969901a0d843624!2sFOXAISR!5e0!3m2!1sen!2sin!4v1707464967318!5m2!1sen!2sin"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy">
                </iframe>
              </div>
            </div> */}
          </div>
          {/* Office Detail End here */}

          {/* Fill the form  start*/}
          {/* <div className="contacts-form card col-lg-6 col-md-6 col-sm-12 ">
            <h2 className="text-center mt-5">Fill The Form</h2>
            <form className="form-group contacts-form-group" >
              <label className="form-label" >Name</label><br />
              <input type="text" className="form-control rounded-pill custom-placeholder custom-input" placeholder="Enter Name" /><br />
              <label className="form-label">Email Address</label><br />
              <input type="email" className="form-control rounded-pill custom-placeholder custom-input" placeholder="Enter email address" /><br />
              <label className="form-label">Phone Number</label><br />
              <input type="number" className="form-control rounded-pill custom-placeholder custom-input" placeholder="Enter phone number" /><br />
              <button className="btn btn-lg form-control rounded-pill" >Submit</button>
            </form>
          </div> */}


          <div className="con-info col-xl-6 col-lg-12 col-md-6 col-sm-12 col-xs-12 ">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d650.3468949009988!2d75.89895288377828!3d22.768439189530305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e3a5d3408dab%3A0x7969901a0d843624!2sFOXAISR!5e0!3m2!1sen!2sin!4v1712662959470!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          {/* Fill the form ends*/}
        </div>

      </div>
    </div >

  );
}
export default Contacts;
