
function OpenPositions() {
    return (
        <div className="container-fluid open-positions-container">
            <h1 className="open-positions-title text-center mt-5">Open Position</h1>
            <div className="row g-5 p-5">
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="position-col d-flex align-items-center justify-content-center">
                        <div className="p-4 custom-md-6-spacing ">
                            <h2 className="pt-3" >Business<br /> Development <br /> Executive</h2>
                            <p className="pt-1" > India, M.P<br />
                                3+ Years Experience</p>
                            <button className="btn rounded-pill text-center apply-button px-5 py-2">Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="position-col d-flex align-items-center justify-content-center">
                        <div className="p-4 custom-md-6-spacing ">
                            <h2 className="pt-3" >Trainee For<br /> Development <br /> Executive</h2>
                            <p className="pt-1" > India, M.P<br />
                                3+ Years Experience</p>
                            <button className="btn rounded-pill text-center apply-button px-5 py-2">Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="position-col d-flex align-items-center justify-content-center">
                        <div className="p-4 custom-md-6-spacing ">
                            <h2 className="pt-3" >Graphic<br /> Designer <br /><br /></h2>
                            <p className="pt-1" > India, M.P<br />
                                3+ Years Experience</p>
                            <button className="btn rounded-pill text-center apply-button px-5 py-2">Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="position-col d-flex align-items-center justify-content-center">
                        <div className="p-4 custom-md-6-spacing ">
                            <h2 className="pt-3">Content<br /> Writer <br /><br /></h2>
                            <p className="pt-1"> India, M.P<br />
                                3+ Years Experience</p>
                            <button className="btn rounded-pill text-center apply-button px-5 py-2">Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="position-col d-flex align-items-center justify-content-center">
                        <div className="p-4 custom-md-6-spacing ">
                        <h2 className="pt-3" >HR<br /> Executive <br /><br /></h2>
                            <p className="pt-1"> India, M.P<br />
                                3+ Years Experience</p>
                            <button className="btn rounded-pill text-center apply-button px-5 py-2">Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="position-col d-flex align-items-center justify-content-center">
                        <div className="p-4 custom-md-6-spacing ">
                        <h2 className="pt-3" >Project<br /> Coordinator <br /><br /></h2>
                            <p className="pt-1"> India, M.P<br />
                                3+ Years Experience</p>
                            <button className="btn rounded-pill text-center apply-button px-5 py-2">Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="position-col d-flex align-items-center justify-content-center">
                        <div className="p-4 custom-md-6-spacing ">
                        <h2 className="pt-3" >Web<br /> Developer <br /><br /></h2>
                            <p className="pt-1"> India, M.P<br />
                                3+ Years Experience</p>
                            <button className="btn rounded-pill text-center apply-button px-5 py-2">Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="position-col d-flex align-items-center justify-content-center">
                        <div className="p-4 custom-md-6-spacing ">
                        <h2 className="pt-3" >Android<br /> Developer <br /><br /></h2>
                            <p className="pt-1"> India, M.P<br />
                                3+ Years Experience</p>
                            <button className="btn rounded-pill text-center apply-button px-5 py-2">Apply Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default OpenPositions;




