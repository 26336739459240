import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ClientReview from './ClientReview';



const clients = [
    { image: '/assests/img/clients.png' },
    // { image: '/assests/img/strix.png' },
    // { image: '/assests/img/khoj.png' },
    // { image: '/assests/img/catt.png' },
    // { image: '/assests/img/fbbat.jpeg' },
    // { image: '/assests/img/safe.png' },
    // { image: '/assests/img/Deu.png' },
    // { image: '/assests/img/isfmg.png' },
    // { image: '/assests/img/abb.png' },
];

function OurClient() {
    // const [settings] = useState({
    //     infinite: true,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     speed: 2000,
    //     autoplaySpeed: 1000,
    //     cssEase: "linear",
    //     arrows: false,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 576,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });

    return (
        <>
         <div className="container ourclient-container">
            <h1 className="ourclients-title text-center">Our Clients</h1>
            {/* <div className="ourclient-mainContainer"> */}
                <div className="row ourclient-row" >
                    <div className="gold-sponsors mt-3">
                        {clients.map((client, index) => (
                            <div className="sponsor-card" key={index}>
                                <img src={client.image} alt={client.name} className="sponsor-image mb-3" style={{width:"100%"}} />
                            </div>
                        ))}
                    </div>
                </div>
            {/* </div> */}
            <ClientReview />
         </div>
        </>
    );
}

export default OurClient;




