import BlogFeed from "../Blog/BLogFeed";
import blogsData from "../Blog/blogsData";
import './HomeStyle.css'



function Client() {

    return (
        <div className="client-section" >
            <div className="row client-contact-section p-5" >
                <div className="col-lg-8 col-md-6 col-sm-12 client-contact-section-fcol px-5" >
                    <h2 className="client-contact-heading">Are you prepared optimize your
                        company's growth potential?</h2>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 client-contact-section-scol" >
                    <a href="/contacts"><button className="btn btn-lg rounded-pill client-contact-button">
                        Contact now</button></a>
                </div>
            </div>


            {/*small blog feed section */}
            <div className="row client-featured-content">
                {/* <a href="/bloginsider" className="col-lg-4 col-md-4 col-sm-12 client-featured-content-item" >
                    <div className="client-feature-item">
                        <div>
                            <img className="img-fluid client-img" src="/assests/img/card1.png" alt="logo" />
                        </div>
                        <div>
                            <h4>Harnessing the Power of AI in Software Development?</h4>
                            <p>Discuss the increasing role of artificial intelligence (AI) in software development....</p>
                            <p className="client-card-content" ><b>02 November 2023</b></p>
                        </div>
                    </div>
                </a>
                <a href="/bloginsider5" className="col-lg-4 col-md-4 col-sm-12 client-featured-content-item" >
                    <div className="client-feature-item">
                        <div>
                            <img className="img-fluid client-img" src="/assests/img/card3.png" alt="logo" />
                        </div>
                        <div>
                            <h4>The Rise of PWA's Bridging the Gap Between Web and Mobile?</h4>
                            <p>Provide tips and best practices for developing and deploying PWAs....</p>
                            <p className="client-card-content" ><b>23 October 2023</b></p>
                        </div>
                    </div>
                </a>
                <a href="/bloginsider3" className="col-lg-4 col-md-4 col-sm-12 client-featured-content-item" >
                    <div className="client-feature-item">
                        <div>
                            <img className="img-fluid client-img" src="/assests/img/card2.png" alt="logo" />
                        </div>
                        <div >
                            <h4>Demystifying Blockchain Technology: Beyond Cryptocurrencies.</h4>
                            <p>Explore diverse use cases such as supply chain management, digital identity verification....</p>
                            <p className="client-card-content" ><b>12 January 2024</b></p>
                        </div>
                    </div>
                </a> */}

                <div>
                    <h2 className='text-center mb-5 mt-5'>Read our latest blogs</h2>
                    <BlogFeed blogs={blogsData.slice(0, 3)} />
                </div>

            </div>
        </div >

    );
}

export default Client;
