import './ProductStyle.css'
import WorkProcess from "./WorkProcess";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const imgData = [
  { image: '/assests/img/SS01.webp' },
  { image: '/assests/img/SS02.webp' },
  { image: '/assests/img/SS03.webp' },
  { image: '/assests/img/SS04.webp' },
];

const imgData1 = [
  { image: '/assests/img/AB01.webp' },
  { image: '/assests/img/AB02.webp' },
  { image: '/assests/img/AB04.webp' },
  { image: '/assests/img/AB05.webp' },
];

function Products() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="container-fluid products-container" >
      <div className="row products-row pt-5 text-center" >
        <div className="col-lg-1 col-md-1 col-sm-12 "></div>
        <div className="col-lg-10 col-md-10 col-sm-12  products-col pt-2" >
          <h1 >
            We build products that user love.
          </h1>
        </div>
        <div className=" col-lg-1 col-md-1 col-sm-12 "></div>
        <div className="col-lg-12 col-md-12 col-sm-12  products-col pt-1">
          <p>Our promises designs tomorrow together is at heart of everything we do.</p>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12  products-col pt-3" style={{ backgroundImage: "linear-gradient(to right, #1B1B1B, #1b1b1b)", height: "20rem" }}>
          {/* <img className="img-fluid" src="/assests/img/product-main.webp" alt="logo" /> */}
        </div>
      </div>

      <WorkProcess />


      {/* Simplae Siksha Learning Platform slider*/}
      <div className="container " >
        <div className='leading-content'>
          <div className="text-center">
            <h1>Simple Shiksha : A Online Learning Platform</h1>
            <p>Description of the first reason we give in a simple and understandabe way in this many words</p>
          </div>
          <div style={{ border: "none" }}>
            <Slider {...settings}>
              {imgData.map((client, index) => (
                <div className="slider-card" key={index}>
                  <img src={client.image} alt={client.name} className="sponsor-image mb-3" style={{ width: "100%" }} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      {/*Learning takes flight*/}
      <div className="container" style={{ marginTop: "10rem" }}>
        <div className="row">
          <div className='col-lg-1'></div>
          <div className="col-lg-6 col-md-6 col-sm-12 product-button mb-3">
            <h1><b>Where Learning Takes<br /> Flight and Soars to<br /> Success</b></h1>
            <h4>From personalized tutoring to exam readiness, Simple<br /> Shiksha here to elevate your learning experience to<br /> new heights.</h4>
            <a href="/contacts" ><button className="btn btn-lg rounded-pill visit-now-btn mt-5">Visit Now</button></a>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <img src="/assests/img/SS5.webp" alt="img" className="img-fluid" />
          </div>
          {/* <div className='col-lg-1'></div> */}
        </div>
      </div>

      {/* Empower Educators */}
      <div className='container'>
        <div className='leading-content'>
          <div className="text-center">
            <h1>Empower Educators with Simple Shiksha's Software Suite</h1>
            <p>Transforming Teaching: Intuitive Tools, Valuable Insights, and Seamless Communication
              for Enhanced Learning Experiences</p>
          </div>

          <div className="row justify-content-center mt-5">

            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="box-feature">
                <div className='row '>
                  <div className='col-lg-3'>
                    <img className="img-fluid mx-auto" src="/assests/img/SS6.webp" alt="logo" />
                  </div>
                  <div className='col-lg-9'>
                    <h3><b>Course Creation Tools</b></h3>
                    <p>Easily build customized courses to meet teaching objectives, ensuring engaging and tailored learning experiences for students.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="box-feature">
                <div className='row '>
                  <div className='col-lg-3'>
                    <img className="img-fluid mx-auto" src="/assests/img/SS7.webp" alt="logo" />
                  </div>
                  <div className='col-lg-9'>
                    <h3><b>Data Analytics Insights</b></h3>
                    <p>Access valuable data to track learner progress and improve course effectiveness through insightful analytics tools.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div className="row justify-content-center">

            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="box-feature">
                <div className='row '>
                  <div className='col-lg-3'>
                    <img className="img-fluid mx-auto" src="/assests/img/SS8.webp" alt="logo" />
                  </div>
                  <div className='col-lg-9'>
                    <h3><b>Interactive Assessments</b></h3>
                    <p>Engage students with dynamic quizzes, assignments, and exams to enhance comprehension and foster active learning participation.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="box-feature">
                <div className='row '>
                  <div className='col-lg-3'>
                    <img className="img-fluid mx-auto" src="/assests/img/SS9.webp" alt="logo" />
                  </div>
                  <div className='col-lg-9'>
                    <h3><b>Communication Channels</b></h3>
                    <p>Facilitate collaboration and support with integrated messaging systems and discussion forums for seamless interaction and assistance.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      {/* Empower Educators ends */}

      <div className="container d-flex align-items-center justify-content-center mt-5 mb-5">
        <div>
          <hr style={{ width: "25rem" }} />
        </div>
      </div>

      {/* Assist buddi Learning Platform slider */}
      <div className="container justify-content-center" >
        <div className='leading-content-product'>
          <div className="text-center">
            <h1>Assist Buddi : One Stop Business Solution</h1>
            <p>Transforming Teaching: Intuitive Tools, Valuable Insights, and Seamless Communication
              for Enhanced Learning Experiences</p>
          </div>
          <div>
            <Slider {...settings}>
              {imgData1.map((client, index) => (
                <div className="slider-card" key={index}>
                  <img src={client.image} alt={client.name} className="sponsor-image mb-3" style={{ width: "100%" }} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>


      {/* Assist buddi services */}
      <div className='container leading-content'>
        <div className="text-center">
          <h1>Assist Buddi’s Services</h1>
        </div>

        {/* four of row 1 */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="300">
              <div className="box-feature mb-4 text-center">
                <img className="img-fluid mx-auto" src="/assests/img/AB4.webp" alt="logo" style={{ width: "auto", height: "5rem" }} />
                <h3>Live Chat</h3>
                <p>Engage your customers or client with live chat, solve query, generate leads, etc</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="500">
              <div className="box-feature mb-4 text-center">
                <img className="img-fluid mx-auto" src="/assests/img/AB5.webp" alt="logo" style={{ width: "auto", height: "5rem" }} />
                <h3>Chat Bot</h3>
                <p>We make your solution easy, we train our AI Bot based on your system.</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="400">
              <div className="box-feature mb-4 text-center">
                <img className="img-fluid mx-auto" src="/assests/img/AB6.webp" alt="logo" style={{ width: "auto", height: "5rem" }} />
                <h3>Tickets</h3>
                <p>Assist Buddi helps in creating tickets for your customer query and keep track.</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="400">
              <div className="box-feature mb-4 text-center">
                <img className="img-fluid mx-auto" src="/assests/img/AB7.webp" alt="logo" style={{ width: "auto", height: "5rem" }} />
                <h3>Analytics</h3>
                <p>Analyse your customer needs and which help in grow your business to next level.</p>
              </div>
            </div>

            {/* four of row 2 */}

            <div className="col-12 col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="300">
              <div className="box-feature mb-4 text-center">
                <img className="img-fluid mx-auto" src="/assests/img/AB8.webp" alt="logo" style={{ width: "auto", height: "5rem" }} />
                <h3>Automation</h3>
                <p>Simplify tasks, boosting productivity with  automated processes.</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="500">
              <div className="box-feature mb-4 text-center">
                <img className="img-fluid mx-auto" src="/assests/img/AB9.webp" alt="logo" style={{ width: "auto", height: "5rem" }} />
                <h3>Easy Integration</h3>
                <p>Streamline workflows effortlessly with AssistBuddi's seamless integrations.</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="400">
              <div className="box-feature mb-4 text-center">
                <img className="img-fluid mx-auto" src="/assests/img/AB10.webp" alt="logo" style={{ width: "auto", height: "5rem" }} />
                <h3>Global access</h3>
                <p>Access your support solution globally, with our Assist Buddi App.</p>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="400">
              <div className="box-feature mb-4 text-center">
                <img className="img-fluid mx-auto" src="/assests/img/AB11.webp" alt="logo" style={{ width: "auto", height: "5rem" }} />
                <h3>Track Agent</h3>
                <p>We help you in tracking agents for the healthy customer relationship.</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Assist buddi services end */}




      {/*Learning takes flight*/}
      <div className="container mb-5" style={{ marginTop: "10rem" }}>
        <div className="row">
          <div className='col-lg-1'></div>
          <div className="col-lg-5 col-md-5 col-sm-12 product-button">
            <img src="/assests/img/SS12.webp" alt="img" className="img-fluid" />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <h1><b>Elevating Customer<br /> Support to New<br /> Heights</b></h1>
            <h4>From AI-powered solutions to round-the-clock support, we're here to elevate your customer interactions to a whole new level.</h4>
            <a href="/contacts" ><button className="btn btn-lg rounded-pill visit-now-btn mt-5">Visit Now</button></a>
          </div>
          <div className='col-lg-1'></div>
        </div>
      </div>
    </div >
  );


}

export default Products;
