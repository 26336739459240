import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

export default function App() {
  return (
    <MDBFooter className='text-center text-lg-start text-muted' style={{ background: "#1e1e1e" }}>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom' style={{ color: "#fff" }}>
        <div className='me-5 d-none d-lg-block'>
          <span className='text-uppercase'>connect with us </span>
        </div>

        <div>
          <a href='https://www.linkedin.com/company/foxaisr' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='https://twitter.com/foxaisr' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='https://www.instagram.com/foxaisr_official' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='https://www.facebook.com/foxaisr' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
        </div>
      </section>

      <section className='' style={{ color: "#fff" }}>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4' style={{ marginTop: "3rem" }}>
              <h6 className='text-uppercase fw-bold mb-4'>
                <img className='img-fluid' src="/assests/img/logowhite.png" alt="Logo" />
              </h6>
              <p>
                Revolutionizing Business with innovative
                Empowerment Solutions
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Quick links</h6>
              <div>
                <p>
                  <a href='/' className='text-reset' style={{ textDecoration: "none" }}>
                    Home
                  </a>
                </p>
                <p>
                  <a href='/products' className='text-reset' style={{ textDecoration: "none" }}>
                    Product
                  </a>
                </p>
                <p>
                  <a href='/careers' className='text-reset' style={{ textDecoration: "none" }}>
                    Careers
                  </a>
                </p>
                <p>
                  <a href='/blogs' className='text-reset' style={{ textDecoration: "none" }}>
                    Blogs
                  </a>
                </p>
                <p>
                  <a href='/contacts' className='text-reset' style={{ textDecoration: "none" }}>
                    Contacts
                  </a>
                </p>
              </div>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a href='https://www.simpleshiksha.com/' target='_blank' className='text-reset' style={{ textDecoration: "none" }}>
                  Shimple Shiksha
                </a>
              </p>
              <p>
                <a href='https://www.assistbuddi.com/' target='_blank' className='text-reset' style={{ textDecoration: "none" }}>
                  Assist Buddi
                </a>
              </p>
              {/* <p>
                <a href='#!' className='text-reset'>
                  Vue
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Laravel
                </a>
              </p> */}
            </MDBCol>



            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              {/* <p>
                <MDBIcon icon="home" className="me-2" />
                238, Scheme No. 114, AB Rd, opposite Mahindra Showroom, Part 1, Vijay Nagar, Indore, Madhya Pradesh 452010
              </p> */}
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                info@foxaisr.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91 808 437 8326
              </p>
              <p>
                <MDBIcon fab icon="whatsapp" className="me-3" /> +91 829 205 0483
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', color: "#fff" }}>
        &copy; Foxaisr Technology Private Limited @ 2024 | All Rights Reserved
      </div>
    </MDBFooter>
  );
}