function OurServices() {
    return (
        <div className="container ourservices-container py-5 my-5">
            {/* Our goals starts */}
            <div className="row ourgoals-row">
                <div className="ourgoals-fcol col-lg-6 col-md-6 col-sm-12" >
                    <img className="img-fluid" src="/assests/img/goal.gif" alt="logo" />
                </div>
                <div className=" ourgoals-scol  col-lg-6 col-md-6 col-sm-12 mt-5 ">
                    <p >Whether you are an enterprise or a startup,
                        this is where our creative and development
                        team plays a dedicated role to <b>achieve your
                            goals.</b></p>
                    {/* <p>Dedicated role to achieve your goals.?</p> */}
                </div>
            </div >
            {/* Our goals ends */}

            {/* Our Services starts */}
            <h1 className="ourservices-title text-center">Our Services</h1>
            <div className="container ">
                <div className="row ourservices-row pt-5 gx-5 gy-4">
                    <div className="ourservices-col   col-lg-6 col-md-6 col-sm-12 ">
                        <div className="row ourservices-innerrow p-3">
                            <div className="ourservices-img-div  col-lg-2 col-md-2 col-sm-12  p-2"  >
                                <img className="img-fluid" src="/assests/img/Webdesigning.svg" alt="logo" />
                            </div>
                            <div className="ourservices-col-div col-lg-10 col-md-10 col-sm-12 p-2">
                                <h2>Web Design & Developmnet</h2>
                                <p>Transform your online presence with our expert Web Design & Development solutions, tailored to elevate your brand and engage your audience effectively.</p>
                            </div>
                        </div>
                    </div>
                    <div className="ourservices-col col-lg-6 col-md-6 col-sm-12  " >
                        <div className="row ourservices-innerrow p-3">
                            <div className="ourservices-img-div  col-lg-2 col-md-2 col-sm-12 p-2 ">
                                <img className="img-fluid" src="/assests/img/Mobiledevelopment.svg" alt="logo" />
                            </div>
                            <div className="ourservices-col-div  col-lg-10  col-md-10 col-sm-12 p-2">
                                <h2>Mobile Application</h2>
                                <p>Smartphone’s are being used on a regular basis and most of the users engaged on smart phones. To elevate your business presence.</p>
                            </div>
                        </div>
                    </div >
                    <div className=" ourservices-col col-xxl-6 col-lg-6 col-md-6 col-sm-12 " >
                        <div className="row ourservices-innerrow p-3">
                            <div className="ourservices-img-div  col-lg-2  col-md-2 col-sm-12 p-2">
                                <img className="img-fluid" src="/assests/img/E-Commerce.svg" alt="logo" />
                            </div>
                            <div className="ourservices-col-div col-lg-10 col-md-10 col-sm-12  p-2">
                                <h2>E-Commerce</h2>
                                <p>E-commerce revolutionizes shopping, bridging global markets with a click. From gadgets to groceries, it's the digital marketplace's slick.</p>
                            </div>
                        </div>
                    </div>
                    <div className="ourservices-col  col-lg-6 col-md-6 col-sm-12 " >
                        <div className="row ourservices-innerrow p-3">
                            <div className="ourservices-img-div col-lg-2  col-md-2 col-sm-12  p-2">
                                <img className="img-fluid" src="/assests/img/Softwaredevelopment.svg" alt="logo" />
                            </div>
                            <div className="ourservices-col-div  col-lg-10 col-md-10 col-sm-12  p-2">
                                <h2>Software Development</h2>
                                <p>We specializes in software development in all domains & technologies With a dedicated team of experienced developers.</p>
                            </div>
                        </div>
                    </div>
                    <div className="ourservices-col col-lg-6 col-md-6 col-sm-12 " >
                        <div className="row ourservices-innerrow p-3">
                            <div className="ourservices-img-div  col-lg-2 col-md-2 col-sm-12  p-2" >
                                <img className="img-fluid" src="/assests/img/Webdevelopment.svg" alt="logo" />
                            </div>
                            <div className="ourservices-col-div col-lg-10 col-md-10 col-sm-12  p-2">
                                <h2>PR & Marketing</h2>
                                <p>Elevate your brand's visibility and reputation with our integrated PR & Marketing solutions, designed to captivate audiences and drive impactful results.</p>
                            </div>
                        </div>
                    </div>
                    <div className="ourservices-col  col-lg-6 col-md-6 col-sm-12  " >
                        <div className="row ourservices-innerrow p-3">
                            <div className="ourservices-img-div col-lg-2  col-md-2 col-sm-12  p-2">
                                <img className="img-fluid" src="/assests/img/Support & Maintaince.svg" alt="logo" />
                            </div>
                            <div className="ourservices-col-div  col-lg-10 col-md-10 col-sm-12 p-2">
                                <h2>Support & Maintenance</h2>
                                <p>It is necessary one's your platform is live. Our team identify and resolve issues immediately they’re reported.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="ourservices-col  col-lg-6 col-md-6 col-sm-12  " >
                        <div className="row ourservices-innerrow p-3">
                            <div className="ourservices-img-div  col-lg-2  col-md-2 col-sm-12  p-2">
                                <img className="img-fluid" src="/assests/img/Softwaredevelopment.svg" alt="logo" />
                            </div>
                            <div className="ourservices-col-div  col-lg-10 col-md-10 col-sm-12  p-2">
                                <h2>Software Development</h2>
                                <p>Description of the first reason we give in a simple and understandabe way in this many words</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="ourservices-col  col-lg-6 col-md-6 col-sm-12  " >
                        <div className="row ourservices-innerrow p-3">
                            <div className="ourservices-img-div col-lg-2  col-md-2 col-sm-12  p-2">
                                <img className="img-fluid" src="/assests/img/Support & Maintaince.svg" alt="logo" />
                            </div>
                            <div className="ourservices-col-div col-lg-10 col-md-10 col-sm-12 p-2">
                                <h2>Support & Maintenance</h2>
                                <p>Description of the first reason we give in a simple and understandabe way in this many words</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div >
            {/* Our Services ends */}
        </div >
    );
}

export default OurServices;